/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Inter:wght@100;200;300;400;600;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.carousel-root {
  direction: ltr;
}

/* .carousel .control-dots {
  margin: 78px 0 !important;
  margin-left: 180px !important;
  text-align: start !important;
} */

 .carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  background: gray !important;
  width: 10px !important;
  height: 10px !important;
} 

.carousel .control-dots .dot.selected {
  background: #00609C !important;

}

.carousel .carousel-status {
  display: none;
}

/*** pagination***/

.react-paginate ul {
  display: inline-block;
  margin-left: 20px;
  padding-left: 0;
}

.react-paginate li {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 5px;

}

.react-paginate li a {
  padding: 2px 10px;
  display: inline-block;
  color: #fff;
  outline: none;
}

.react-paginate .selected {
  background: #00609C;
}


.react-paginate li:hover {
  background-color: #00609C;
}


::-webkit-scrollbar {
  width: 3px;
  background: #171717;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  height: 1px;
}


  .slick-prev {
  left: -38px !important;
  width: 30px !important;
  height: 30px !important;

}
.slick-next{
  right: -30px !important;
  width: 30px !important;
  height: 30px !important; 
}

.slick-prev:before {

  font-size: 30px !important;

}

.slick-next:before {

  font-size: 30px !important;
} 

 .slick-slide{
  padding-right: 20px !important;
} 




/* React modal */



.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.arabic-format {
  unicode-bidi: bidi-override;
  direction: rtl;
  font-family: "Cairo", Cairo, sans-serif;
}

.arabic-format1 {
  font-family: "Times New Roman",;
  direction: ltr;
}

/**date picker */
.react-datepicker__input-container>input {
  background-color: rgba(255, 255, 255, 0.18);
  width: 100%;
  padding: 10px 8px;
  color: #A3A3A3;
  appearance: none;

}

.react-datepicker__input-container>input :focus {
  outline: none;
  border: 0px solid;
  color: #A3A3A3;
}


.ReactModal__Overlay--after-open {
  z-index: 10;

}


.fake-class>ul>li {
  list-style-type: disc;
  list-style-position: outside;


}


.content-div:hover {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 87.89%);

}




/*
  -remove the classes below if you have the 'group-hover'property added in your tailwind config file
  -also remove the class from the html template
  */
.content-div:hover .fd-cl {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;

}

.content-div:hover .fd-sh {

  opacity: 1;
  transform: translateY(0px);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  transition-delay: 3600s;
}

.react-datepicker-wrapper{
  @apply !block
}